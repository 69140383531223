import {
    LOGIN_USER,
    LOGIN_USER_SUCCESS,
    LOGIN_USER_FAILURE,
    REGISTER_USER,
    REGISTER_USER_SUCCESS,
    REGISTER_USER_FAILURE,
    LOGOUT_USER,
    FORGOT_PASSWORD,
    FORGOT_PASSWORD_SUCCESS,
    FORGOT_PASSWORD_FAILURE
} from '../actions';

const INIT_STATE = {
    user: "",
    loading: false,
    loginError:"",
    signupError:"",
    error:""
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case LOGIN_USER:
            return { ...state, loading: true };
        case LOGIN_USER_SUCCESS:
            return { ...state, loading: false, user: action.payload,loginError:'' };
        case LOGIN_USER_FAILURE:
            return { ...state, loading: false, user:"",loginError: action.payload };
        case REGISTER_USER:
            return { ...state, loading: true };
        case REGISTER_USER_SUCCESS:
            return { ...state, loading: false, user: action.payload,signupError:'' };
        case REGISTER_USER_FAILURE:
            return { ...state, loading: false, user:"",signupError: action.payload };
        case FORGOT_PASSWORD:
            return { ...state, loading: true };
        case FORGOT_PASSWORD_SUCCESS:
            return { ...state, loading: false, user: action.payload,error:''};
        case FORGOT_PASSWORD_FAILURE:
            return { ...state, loading: false, error: action.payload };
        case LOGOUT_USER:
            return { ...state, user: null };
        default: return { ...state };
    }
}


import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { auth } from '../../helpers/Firebase';
import {
    LOGIN_USER,
    REGISTER_USER,
    LOGOUT_USER,
    FORGOT_PASSWORD
} from '../actions';

import {
    loginUserSuccess,
    loginUserFailure,
    registerUserSuccess,
    registerUserFailure,
    forgotPasswordSuccess,
    forgotPasswordFailure
} from './actions';
import configName from '../../environment.json';
let env = process.env.REACT_APP_ENV || 'development'
let config = configName[env]
const loginWithEmailPasswordAsync = async (email, password,name) =>{
    let data  =  await fetch(`${config.APIHOST}/api/v1/user/login`, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        headers: {
          'Content-Type': 'application/json',
        },
        body:JSON.stringify({
            "email":email,
            "password":password
        })
      })
      if(data.status === 200) {
        let json = await data.json();
        return json
      }else {
        let json = await data.json();
        console.log("The error occured",json) 
        return {statusText:json.message,status:data.status}
      }
}
      
      
        


function* loginWithEmailPassword({ payload }) {
    console.log("The payload is",payload)
    const { email, password,name } = payload.user;
    try {
        const loginUser = yield call(loginWithEmailPasswordAsync, email, password,name);
        console.log("The payload is",loginUser)
        if (loginUser.auth_token) {
            localStorage.setItem('routo-authToken', loginUser.auth_token);
            document.cookie = `routo-sessionid=${loginUser.auth_token};path=/`;
            console.log("The login user is",loginUser)
            yield put(loginUserSuccess(loginUser));
            setTimeout(()=>{window.location.assign(`${config.CLIENT_HOST}/dashboard/index.html`)},2000)   
        } else {
         console.log("The response data ",loginUser)
            yield put(loginUserFailure(loginUser));
        }
    } catch (error) {
        console.log('login error : ', error)
    }
}

const registerWithEmailPasswordAsync = async (email, password,name,organization) =>{
    let data  = await fetch(`${config.APIHOST}/api/v1/user/signup`, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        headers: {
          'Content-Type': 'application/json',
        },
        body:JSON.stringify({
            "email":email,
            "password":password,
            "name":name,
            "account_name":organization
    
        })
      })
      if(data.status === 200) {
        let json = await data.json();
        return json
      }else {
        let json = await data.json();;  
        return {statusText:json.message,status:data.status}
      }
}


function* registerWithEmailPassword({ payload }) {
    const { email, password,name,organization} = payload.user;
    console.log("the payload is ",payload.history.location.pathname)
    const {history} = payload.history
    try {
        const registerUser = yield call(registerWithEmailPasswordAsync, email, password,name,organization);
        if (registerUser.status === true) {
            console.log('register success :', registerUser);
            history.push({
                pathname: '/auth/login',
                state: { 
                    from: payload.history.location.pathname
                }
             })
            yield put(registerUserSuccess(registerUser));
            
        } else {
            console.log('register failed :', registerUser)
            registerUser.status === 500 ? 
            yield put(registerUserFailure({status:500,statusText:'Internal Server Error'}))
            :
            yield put(registerUserFailure(registerUser))
        }
    } catch (error) {
        console.log('register error : ', error)
    }
}

const forgotPasswordAsync = async (email, password) =>
    await auth.forgotPasswordAsync(email, password)
        .then(authUser => authUser)
        .catch(error => error);

function* forgotPasswordWithEmail({ payload }) {
    const { email, password } = payload.user;
    const { history } = payload
    try {
        const registerUser = yield call(forgotPasswordAsync, email, password);
        if (!registerUser.message) {
            localStorage.setItem('routo-authToken', registerUser.user.uid);
            yield put(forgotPasswordSuccess(registerUser));
            history.push('/')
        } else {
            console.log('forgot password failed :', registerUser.message)
            yield put(forgotPasswordFailure(registerUser));
        }
    } catch (error) {
        console.log('register error : ', error)
    }
}


const logoutAsync = async (history) => {
    await auth.signOut().then(authUser => authUser).catch(error => error);
    history.push('/')
}

function* logout({payload}) {
    const { history } = payload
    try {
        yield call(logoutAsync,history);
        localStorage.removeItem('user_id');
    } catch (error) {
    }
}



export function* watchRegisterUser() {
    yield takeEvery(REGISTER_USER, registerWithEmailPassword);
}

export function* watchLoginUser() {
    yield takeEvery(LOGIN_USER, loginWithEmailPassword);
}

export function* watchLogoutUser() {
    yield takeEvery(LOGOUT_USER, logout);
}

export function* watchForgotPassword() {
    yield takeEvery(FORGOT_PASSWORD, forgotPasswordWithEmail);
}


export default function* rootSaga() {
    yield all([
        fork(watchLoginUser),
        fork(watchLogoutUser),
        fork(watchRegisterUser),
        fork(watchForgotPassword)
    ]);
}
import React, { Component } from "react";
import { Row, Card, Form, Label, Input, Button } from "reactstrap";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { registerUser } from "../../redux/actions";

import IntlMessages from "../../helpers/IntlMessages";
import { Colxx } from "../../components/common/CustomBootstrap";
import routoLogo from '../../assets/image/routo-logo-hex.png';
class Register extends Component {

  constructor(props) {
    super(props)
    this.email = React.createRef()
    this.password = React.createRef()
    this.name = React.createRef()
  }
  state = {
    email: "",
    password: "",
    name: "",
    company: "",
    organization: "",
    showMsg: false
  };

  onUserRegister = async (event) => {
    event.preventDefault();
    if (this.state.email !== "" && this.state.password !== "" && this.state.name !== "") {
      await this.props.registerUser(this.state, this.props);
      this.setState({ showMsg: true })
    }
  }
  handleChnage = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }
  render() {
    const { email, password, name, organization, showMsg } = this.state;
    const { signupError } = this.props;
    console.log("The props are", signupError)
    return (
      <Row className="h-100">
        <Colxx xxs="12" md="10" className="mx-auto my-auto">
          <Card className="auth-card">
            <div className="position-relative image-side ">
              <p className="text-white h2">serverless simplified</p>
              <hr style={{ "maxWidth": "100px", "margin": "0px" }} className="mb-4" ></hr>
              <p className="white" style={{ 'marginTop': "40px" }}>
                Routo helps you to quickly build cost effective cloud applications using serverless technologies.
            </p>
            </div>
            <div className="form-side">
              <NavLink to={`/`} >
                <img src={routoLogo} style={{ 'padding': '30px 0px', 'height': '100px' }} alt="login page logo" />
              </NavLink>
              {signupError.statusText && showMsg ? <p className="text-danger"><i className="icon-warning-sign"></i>{'  '}{signupError.statusText}</p> : null}
              {/* {user.status && showMsg?<p className="text-success"><i className="fas fa-check-circle"></i>{' '}You have sucessfully registered. Click here to <span><NavLink to={`/auth/login`} >Login </NavLink></span></p>:null} */}
              <Form onSubmit={this.onUserRegister}>
                <Label className="form-group has-float-label mb-4">
                  <Input type="name" name="name" required={true} placeholder="name" onChange={this.handleChnage.bind(this)} value={name} />
                  <IntlMessages id="Name" />
                </Label>
                <Label className="form-group has-float-label mb-4">
                  <Input type="name" name="organization" required={true} placeholder="organization" onChange={this.handleChnage.bind(this)} value={organization} />
                  <IntlMessages id="Organization" />
                </Label>
                <Label className="form-group has-float-label mb-4">
                  <Input type="email" name="email" required={true} placeholder="demo@gmail.com" onChange={this.handleChnage.bind(this)} value={email} />
                  <IntlMessages id="Email" />
                </Label>
                <Label className="form-group has-float-label mb-4">
                  <Input type="password" name="password" required={true} placeholder="xxxxxxxxxxxx" onChange={this.handleChnage.bind(this)} value={password} />
                  <IntlMessages
                    id="Password"
                  />
                </Label>
                <div className="d-flex justify-content-between align-items-center">
                  <NavLink to={`/auth/login`}>
                    <IntlMessages id=" If you are a member, please" />
                    {" "}<span  style={{ 'color': '#145388' }}>login</span>
                  </NavLink>
                  <Button
                    type="submit"
                    color="primary"
                    className="btn-shadow"
                    size="lg"
                    disabled={this.props.loading}
                    onSubmit={this.onUserRegister}
                  >
                    <IntlMessages id="register" />
                  </Button>
                </div>
              </Form>
            </div>
          </Card>
        </Colxx>
      </Row>
    );
  }
}
const mapStateToProps = ({ authUser }) => {
  console.log("The map state to props", authUser)
  const { user, loading, signupError } = authUser;
  return { user, loading, signupError };
};

export default connect(
  mapStateToProps,
  {
    registerUser
  }
)(Register);

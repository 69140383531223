import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAILURE,
  LOGOUT_USER,
  REGISTER_USER,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_FAILURE,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILURE,
} from '../actions';

export const loginUser = (user, history) => ({
  type: LOGIN_USER,
  payload: { user, history }
});
export const loginUserSuccess = (user) => ({
  type: LOGIN_USER_SUCCESS,
  payload: user
});
export const loginUserFailure = (user) => ({
  type: LOGIN_USER_FAILURE,
  payload: user
});

export const registerUser = (user, history) => ({
  type: REGISTER_USER,
  payload: { user, history }
})
export const registerUserSuccess = (user) => ({
  type: REGISTER_USER_SUCCESS,
  payload: user
})
export const registerUserFailure = (user) => ({
  type: REGISTER_USER_FAILURE,
  payload: user
})

export const logoutUser = (history) => ({
  type: LOGOUT_USER,
  payload : {history}
});
export const forgotPassword=(user,history)=>({
  type:FORGOT_PASSWORD,
  payload:{user,history}
})
export const forgotPasswordSuccess = (user)=>({
   type:FORGOT_PASSWORD_SUCCESS,
   payload:user
})

export const forgotPasswordFailure = (user)=>({
  type:FORGOT_PASSWORD_FAILURE,
  payload:user
})
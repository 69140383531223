import React, { Component } from "react";
import { Row, Card, Form, Label, Input, Button } from "reactstrap";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { loginUser } from "../../redux/actions";
import { Colxx } from "../../components/common/CustomBootstrap";
import IntlMessages from "../../helpers/IntlMessages";
import routoLogo from '../../assets/image/routo-logo-hex.png';
import loading from '../../assets/image/loading.gif';
class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      name:"",
      showMsg:false
    };
  }
  onUserLogin=(event)=> {
    event.preventDefault();
    //this.setState({showMsg:true})
    if (this.state.email !== "" && this.state.password !== "") {
      this.props.loginUser(this.state, this.props.history);
      this.setState({showMsg:true})
    }
    event.preventDefault();
  }

  handleChnage = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })

  }

  render() {
    const {loginError} = this.props
    const {password,email, showMsg} = this.state
    console.log("The history location", this.props.location)
    return (
      <Row className="h-100">
        <Colxx xxs="12" md="10" className="mx-auto my-auto">
          <Card className="auth-card">
            <div className="position-relative image-side ">
              <p className="text-white h2">serverless simplified</p>
              <hr style={{"maxWidth": "100px","margin": "0px"}} className="mb-4"></hr>
              <p className="white"  style={{'marginTop':"40px"}}>
              Routo helps you to quickly build cost effective cloud applications using serverless technologies.
              </p>
            </div>
            <div className="form-side">
              <NavLink to={`/`} >
                <img src={routoLogo} style={{'padding':'30px 0px','height': '100px'}} alt="login page logo"/>
                {this.props.loading?(<img src={loading} style={{'height':'100px',marginLeft:'10px'}} alt="login page loading"/>):null}
              </NavLink>
              {loginError.statusText && showMsg ?<p className="text-danger"><i className="icon-warning-sign"></i>{' '}{loginError.statusText}</p>:null}
              {this.props.history.action === "PUSH" && this.props.history.location.state && !showMsg?<p className="text-success"><i className="fas fa-check-circle"></i>{' '}You have sucessfully registered.</p>:null}
              <Form onSubmit={this.onUserLogin}>
                <Label className="form-group has-float-label mb-4">
                  <Input type="email" name="email" required={true} onChange={this.handleChnage.bind(this)} value={email} />
                  <IntlMessages id="Email" />
                </Label>
                <Label className="form-group has-float-label mb-4">
                  <Input type="password" name="password" required={true} onChange={this.handleChnage.bind(this)} value={password} />
                  <IntlMessages
                    id="Password"
                  />
                </Label>
                <div className="d-flex justify-content-between align-items-center">
                  <NavLink to={`/auth/forgot-password`}  style={{'color':'#145388'}}>
                    <IntlMessages id="Forgot password?" />
                  </NavLink>
                  <Button
                    type="submit"
                    color="primary"
                    className="btn-shadow"
                    disabled={this.props.loading}
                    size="lg"
                    onSubmit={this.onUserLogin}
                  >
                    <IntlMessages id="login" />
                  </Button>
                </div>
                <div>
                If you are not a member,{" "}
                <NavLink to={`/auth/register`} style={{'color':'#145388'}}>
                  please register
                </NavLink>
                </div>
              </Form>
            </div>
          </Card>
        </Colxx>
      </Row>
    );
  }
}
const mapStateToProps = ({ authUser }) => {
  console.log("The map state to props", authUser)
  const { user, loading ,error,loginError} = authUser;
  return { user, loading, error,loginError};
};

export default connect(
  mapStateToProps,
  {
    loginUser
  }
)(Login);

import React, { Component } from "react";
import { connect } from "react-redux";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from "react-router-dom";
import { IntlProvider } from "react-intl";
import "./helpers/Firebase";
//import AppLocale from "./lang";
import NotificationContainer from "./components/common/react-notifications/NotificationContainer";
import main from "./views";
import user from "./views/user";
import { getDirection } from "./helpers/Utils";


class App extends Component {
  componentWillMount() {
    const direction = getDirection();
    if (direction.isRtl) {
      document.body.classList.add("rtl");
      document.body.classList.remove("ltr");
    } else {
      document.body.classList.add("ltr");
      document.body.classList.remove("rtl");
    }
  }

  render() {
    //const { locale} = this.props;
   // const currentAppLocale = AppLocale[locale];

    return (
      <div className="h-100">
        <IntlProvider
          //  locale={currentAppLocale.locale}
          //  messages={currentAppLocale.messages}
        >
          <React.Fragment>
            <NotificationContainer />
            {/* {isMultiColorActive && <ColorSwitcher />} */}
            <Router  basename="/app">
              <Switch>
                <Route path="/auth" component={user} />
                <Route path="/" exact component={main} />
                <Redirect to="/auth" />
              </Switch>
            </Router>
          </React.Fragment>
        </IntlProvider>
      </div>
    );
  }
}

const mapStateToProps = ({ authUser}) => {
  const { user: loginUser } = authUser;
  return { loginUser};
};
const mapActionsToProps = {};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(App);
